<template>
  <i class="loader"><SvgoLogostar /></i>
</template>

<script lang="ts" setup>
</script>

<style lang="scss" scoped>
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loader {
  display: block;
  width: 2em;
  height: 2em;
  animation: spin 2s infinite;

  svg {
    width: 100%;
    height: 100%;
  }

}

</style>